import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import "../../styles/services.scss"
import News from "../../components/News"
import OdooTopSection from "../../components/OdooTopSection"
import Dynamics365OptimizationImageWithTextSection from "../../components/Dynamics365OptimizationImageWithTextSection"
import WhyChooseVisiomateforD365 from "../../components/WhyChooseVisiomateforD365"
import Dynamics365SupportSimplified from "../../components/Dynamics365SupportSimplified"
import Wethoughtyoumighthavequestions from "../../components/Wethoughtyoumighthavequestions"
const Index = ({ data }) => {
  return (
    <div className="dynamics-parent-class">
      <div className="dynamics365Support">
        <Layout pageTitle={"Visiomate - Support for Dynaimcs 365 Operations"}
        descrip = {"We offer comprehensive support services for Dynamics 365 Finance & Operations, including troubleshooting, upgrades, customizations, and training. Keep your system running smoothly."}>
          <OdooTopSection
            headerData={{
              title:
                "Elevate Your Dynamics 365 Journey with Comprehensive, Reliable, and Unmatched Support Services Unlocking Excellence",
            }}
            data={data}
          />
          <Dynamics365OptimizationImageWithTextSection
            content={{
              heading:
                "Maximizing Dynamics 365 Excellence: The Power of Expert Support",
              description:
                "A proficient Dynamics 365 support team offers prompt issue resolution, expert guidance for customization, and ensures optimal system performance. They contribute to user training, assist in tailoring the platform to specific needs, and prioritize security and compliance. With strategic insights, the team aids in future-proofing the system, serving as a proactive partner for seamless and aligned operations with organizational objectives.",
            }}
            data={data}
          />
          <WhyChooseVisiomateforD365
            data={{
              title: "Why Choose Visiomate for D365 Support Team?",
              headings: [
                {
                  heading: "In-Depth Knowledge",
                  description:
                    "Visiomate team possesses a deep understanding and mastery of the Dynamics 365 platform, navigating complex issues with ease and providing effective solutions. Their knowledge extends to best practices, customizations, and system integrations.",
                },
                {
                  heading: "Proactive Issue Resolution",
                  description:
                    "Our team anticipates and identifies potential issues before they impact operations. Proactive monitoring, quick diagnostics, and efficient problem-solving contribute to minimal downtime and optimal system health.",
                },
                {
                  heading: "Customization Expertise",
                  description:
                    "We have extensive experience in customizing D365 to align with specific business requirements. Their proficiency ensures that customizations are strategically implemented, enhancing system flexibility and scalability.",
                },
                {
                  heading: "Cost-Effective Solutions",
                  description:
                    "While expertise comes at a cost, choosing Visiomate ensures a cost-effective approach by minimizing downtime, preventing critical issues, and optimizing the overall efficiency of your D365 environment",
                },
              ],
            }}
            dataFormImages={data}
          />
          <Dynamics365SupportSimplified />
          <Wethoughtyoumighthavequestions
            data={[
              {
                question:
                  "What support services are available for Dynamics 365?",
                answer:
                  "Dynamics 365 offers various support services to help organizations address technical issues, troubleshoot problems, and maximize the value of their D365 investments. These support services may include:",
                answer1:
                  "- Technical Support: Assistance with resolving technical issues, errors, or system problems encountered in Dynamics 365.",
                answer2:
                  "- Consulting Services: Guidance, best practices, and advisory services to help organizations optimize D365 solutions and achieve their business objectives.",
                answer3:
                  "- Training and Education: Training programs, workshops, and resources to help users and administrators learn how to use Dynamics 365 effectively.",
                answer4:
                  "- Community Support: Online forums, user groups, and communities where users can seek advice, share knowledge, and collaborate with peers and experts.",
                answer5:
                  "- Documentation and Resources: Access to technical documentation, guides, tutorials, and knowledge base articles to help users troubleshoot issues and find solutions.",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
              {
                question: "How do I access support services for Dynamics 365?",
                answer:
                  "Organizations can access support services for Dynamics 365 through various channels, including:",
                answer1:
                  "- Microsoft Support: Direct support from Microsoft through online portals, phone, email, or chat channels.",
                answer2:
                  "- Support services offered by Visiomate, consultants, 3rd party integration, troubleshooting and solution providing for Dynamics 365.",
                answer3:
                  "- Online Resources: Self-service support options such as documentation, knowledge base articles, forums, and community resources available on the Microsoft website.",
                answer4:
                  "- Training and Education: Training programs, workshops, and certification courses offered by Microsoft Learning Partners to help users build skills and knowledge in Dynamics 365.",
                answer5: "",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
              {
                question:
                  "What types of issues can be addressed through support services for Dynamics 365?",
                answer:
                  "Support services for Dynamics 365 can address a wide range of issues, including:",
                answer1:
                  "- Technical Issues: Errors, bugs, or system malfunctions encountered in Dynamics 365 applications or services.",
                answer2:
                  "- Configuration and Setup: Assistance with configuring, customizing, or deploying D365 solutions to meet specific business requirements.",
                answer3:
                  "- Integration and Connectivity: Troubleshooting issues related to integrations with third-party systems, data migrations, or connectivity problems.",
                answer4:
                  "- Performance and Optimization: Guidance on optimizing system performance, improving scalability, and enhancing user productivity in Dynamics 365.",
                answer5:
                  "- Training and Adoption: Support for training users, onboarding new employees, and fostering user adoption of Dynamics 365 solutions.",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
              {
                question:
                  "How do I escalate issues or request additional support for Dynamics 365?",
                answer:
                  "If you encounter an issue that cannot be resolved through standard support channels, you may need to escalate the issue or request additional support. This can typically be done by:",
                answer1:
                  "- Contacting Microsoft Support and requesting escalation to a higher support tier or specialized team.",
                answer2:
                  "- Engaging with your Microsoft Certified Partner or consultant to escalate the issue on your behalf and seek additional assistance.",
                answer3:
                  "- Exploring premium support options or consulting services offered by Microsoft or third-party providers for more comprehensive support and assistance.",
                answer4: "",
                answer5: "",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
              {
                question:
                  "What are the benefits of investing in support services for Dynamics 365?",
                answer:
                  "Investing in support services for Dynamics 365 offers several benefits, including:",
                answer1:
                  "- Timely resolution of technical issues and problems, minimizing downtime and disruption to business operations.",
                answer2:
                  "- Access to expertise, best practices, and guidance from Microsoft and certified partners to optimize D365 solutions and achieve business objectives.",
                answer3:
                  "- Enhanced user satisfaction and productivity through training, education, and ongoing support services.",
                answer4:
                  "- Peace of mind knowing that you have access to resources, documentation, and community support to address any challenges or questions that arise during your D365 journey.",
                answer5: "",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
              {
                question:
                  "How can I choose the right support services for my organization's needs?",
                answer:
                  "When choosing support services for Dynamics 365, consider factors such as:",
                answer1:
                  "- Level of Support: Determine the level of support you need, such as basic technical support, consulting services, training, or premium support options.",
                answer2:
                  "- Expertise and Experience: Evaluate the expertise, experience, and certifications of support providers, including Microsoft Support, Certified Partners, and consultants.",
                answer3:
                  "- Service Level Agreements (SLAs): Review SLAs to understand response times, resolution targets, and availability of support resources.",
                answer4:
                  "- Cost and Budget: Consider the cost of support services and how they fit within your organization's budget and resource constraints.",
                answer5:
                  "- Feedback and Recommendations: Seek feedback and recommendations from other organizations, peers, or industry experts who have experience with the support services you are considering.",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
            ]}
            title="We thought you might have questions"
          />
          <News isAbout={true} career={true} />
        </Layout>
      </div>
    </div>
  )
}

export default Index
export const query = graphql`
  query supportImages {
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odooimg: file(relativePath: { eq: "Maskgroupcopy.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markFlag: file(
      relativePath: { eq: "4879902_check_flag_mark_ok_icon_1_copy.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    RSSImages1: file(
      relativePath: { eq: "DemoImages/jaredd-craig-HH4WBGNyltc-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    RSSImages2: file(
      relativePath: {
        eq: "DemoImages/kourosh-qaffari-RrhhzitYizg-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SecondDivImg: file(
      relativePath: { eq: "colleagues-giving-fist-bump 1.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
